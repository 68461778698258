.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-y: scroll;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.mainBG {
  background-image: url("../photos/bg-01.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  overflow: hidden;
  -o-background-size: cover;
  background-size: cover;
}

.mainCard {
  align-self: center;
  position: relative;
  width: 80vmax;
  margin: 10px;
  height: auto;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.imgCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.loginFrame {
  align-self: center;
  position: relative;
  margin: 10px;
  height: auto;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.center {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #864deb;
}

p {
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  line-height: 1.5em;
}

h1 {
  font-family: "Rubik", sans-serif;
}

h1.big {
  font-size: 2.5em;
}

h2.medium {
  font-size: 2em;
}

input {
  font-family: "Rubik", sans-serif;
  outline: none;
  border: none;
}

.logout {
  text-align: center;
  color: whitesmoke;
  font-family: "Rubik", sans-serif;
  flex-direction: column;
  align-self: center;
}

.userButtons {
  display: flex;
  min-width: 200px;
  justify-content: space-between;
}

.modal {
  border-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  min-width: 360px;
  background-color: aliceblue;
  z-index: 99;
  top: 40%;
  left: 50%;
  box-shadow: 5px 10px #888888;
  transform: translate(-50%, -50%);
}

.addButton {
  background-color: #667eea;
  margin-right: 2%;
  align-self: center;
  color: aliceblue;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
}

.modalBG {
  position: absolute;
  width: 100vw;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 250vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.modal-space {
  margin-top: 5%;
  margin-right: 5%;
  margin-left: 5%;
  justify-content: space-around;
}

.modalHeader {
  display: flex;
  justify-content: space-around;
}

.modalHeaderOne {
  margin-left: 10%;
}

.closeModal {
  margin-top: 5%;
  color: #a64bf4;
}

.closeModal:hover {
  cursor: pointer;
}

.updateDetailsButton {
  font-size: 20px;
  align-self: center;
  width: 200px;
  color: aliceblue;
  background-color: #864deb;
}

.heb {
  direction: rtl;
}

.card-container {
  height: 50px;
  border-radius: 5px;
  margin-top: 5px;
  min-width: 150px;
  background-color: #864deb;
  display: flex;
  flex-direction: row;
  direction: rtl;
  justify-content: space-between;
}

.card-element {
  align-self: center;
  margin-right: 5%;
  color: azure;
}

.childText {
  margin-right: 5px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Rubik", sans-serif;
  font-size: x-large;
}

.childrenSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5%;
}

.childrenHeader {
  text-align: end;
  margin-right: 5%;
  font-size: 2em;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #864deb;
}

.childrenWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.childrenHeaderSignUp {
  display: flex;
  font-size: 1.7em;
  margin-top: 3%;
  margin-bottom: 3%;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #864deb;
}

.card-button {
  margin-top: 0;
  margin-bottom: 0;
  width: auto;
  height: auto;
  align-self: flex-end;
  color: azure;
  margin-left: 10px;
}
.buttonRemove {
  margin-right: 0;
  left: 0;
}

.swal2-html-container {
  direction: rtl;
}

.swal2-radio {
  direction: rtl;
}

.childrenCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-right: 5%;
  margin-top: 5%;
}

.swal-button {
  background-color: #a64bf4;
}

.swal-button--cancel {
  color: white;
}

.swal-text {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 25px;
}

.select {
  width: 20vw;
  margin-top: 10px;
  /* justify-content: space-between;
    align-self: center; */
}

.logOutButton {
  cursor: pointer;
}

.logOutButton:hover {
  color: black;
}

.swal2-popup {
  width: 80% !important;
}

.swal2-styled.swal2-confirm {
  width: 100%;
}

.swal2-styled.swal2-deny {
  width: 100%;
}

.swal2-validation-message {
  direction: rtl;
}

.inputName {
  line-height: 2em;
  font-size: 1.5em;
  justify-content: center;
  margin-right: 5px;
  background: transparent;
  border-bottom: 1px solid black;
}

.input {
  align-self: center;
  width: 100%;
  font-size: 17px;
  color: #333333;
  line-height: 1.2;
  display: block;
  margin-right: 10px;
  background: transparent;
  border-bottom: 1px solid black;
}

.centerBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}

.inputTitle {
  font-family: "Rubik", sans-serif;
  font-size: 25px;
  width: 170px;
  align-self: center;
  text-align: right;
}

.childrenTitle {
  width: 10%;
}
.policy{
  position: relative;
  float: right;
  margin-top: 20px;

}

.spaceSide {
  margin-right: 20%;
  margin-left: 20%;
}
button {
  font-family: "Rubik", sans-serif;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: none !important;
  border: none;
  background: transparent;
  width: 100px;
  border-radius: 5px;
  height: 40px;
}
.swal-button {
  background-color: #667eea;
}

.swal-footer {
  display: flex;
  justify-content: center;
}

.swal-button:not([disabled]):hover {
  background-color: #a64bf4;
}

.question {
  color: #667eea;
  font-size: 26px;
}
.toLoginBtn:hover a {
  color: #a64bf4;
}
.loginBtn {
  background: #a64bf4;
  color: white;
  font-size: 20px;
}

.sendBtn {
  background: #a64bf4;
  color: white;
  font-size: 20px;
  margin-top: 50px;
  width: auto;
}
.toLoginBtn {
  color: #667eea;
  font-size: 26px;
  margin-right: 5px;
  background: transparent;
  cursor: pointer;
}

.sections {
  justify-content: space-evenly;
  margin-top: 50px;
}

.imgSide {
  padding: 10px;
  max-width: 300px;
}

.rowCenter {
  max-width: 500px;
  min-width: 200px;
  align-self: center;
}

.toLoginBtn:hover {
  color: #a64bf4;
}

button:hover {
  cursor: pointer;
}

.captcha {
  position: relative;
  overflow: hidden;
}

iframe {
  position: relative;
  width: 100%;
  text-align: center;
  height: auto;
}

#g-recaptcha {
  margin: auto;
}

@media only screen and (min-width: 750px) {
  .loginFrame {
    width: 85vmin;
  }
  .input {
    width: 75%;
    font-size: 22px;
  }
  .modalInput {
    width: 50% !important;
  }
}

@media only screen and (max-width: 650px) {
  .mainCard {
    width: auto;
  }
  .select {
    width: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .names {
    min-width: 285px;
  }
}

@media only screen and (max-height: 375px) {
  .row {
    display: block;
  }
  .centerBtn {
    display: flex;
  }
}
